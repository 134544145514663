import { Component, Prop } from 'vue-property-decorator';
import PlayerApi from '@/support/playerApi';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { IWizardManager } from '@/interfaces';
import { StudioModule } from '@/store/studio';

@Component
export default class EditLogoComponent extends StudioPageBase {

    busy = false;
    imageFile = null;

    options = {
    };

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    parent: any;

    onFileSelected(evt) {
        if (!evt || !evt.target || !evt.target.files || evt.target.files.length != 1) {
            this.imageFile = null;
            return;
        }

        this.imageFile = evt.target.files[0];
        this.done();
        return;
    }

    created() {
        Debug.setDebugModule('EditLogo', this);

        super.created('editLogo', true);
    }

    mounted() {
        super.mounted();
    }

    showExpired() {
        this.done();
    }

    showError(error: string, error2: string = null) {
        this.done();
    }

    async done() {
        if (!this.wizard) return false;
        this.busy = true;

        try {
            let studio = await PlayerApi.uploadLogo(this.imageFile);
            await StudioModule.editStudio(studio);

            Debug.log('editLogo done SUCCEEDED');
            Util.showToast('Saved new logo for ' + this.studio.title);

            this.wizard.back();

            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('editLogo', message);
            Util.showToast('Failed to upload logo for ' + this.studio.title, true);
            return null;
        } finally {
            this.busy = false;
        }
    }

    cancel() {
        this.wizard.back();
    }
}
